import React from "react";

import styles   from "./VKVideoLink.module.css";
import vkvideo  from "../../../assets/svg/vkvideo.svg";

export const VKVideoLink = () => {
  return (
    <a
      href      = "https://vkvideo.ru/playlist/-208533011_11/video-208533011_456239289"
      target    = "_blank"
      rel       = "noopener"
      className = {styles.container}
    >
      <img src={vkvideo} className={styles.icon} alt="Видеоинструкция"/>
      <div className={styles.text}>
        Видеоинструкция по регистрации и заполнению заявки
      </div>
    </a>
  );
};
